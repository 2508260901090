<template>
  <div id="app">
    <!-- <v-overlay :value="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay> -->

    <!-- loading skeleton Start -->
    <!-- <v-col v-show="loading == true">
      <v-sheet
        height="565"
        class="pa-2 mb-n5"
        max-width="6000"
        :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'"
      >
        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="400"
              type="heading"
            ></v-skeleton-loader>
            <v-spacer></v-spacer>
            
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
            ></v-skeleton-loader>
            <v-skeleton-loader
              type="card"
              width="80"
              height="35"
              class="ml-2"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
        
        <div>
          <v-skeleton-loader
            tile
            height="50"
            type="image"
            class="mt-1"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-skeleton-loader
              type="image"
              width="250"
              height="20"
              class="rounded-xl mx-n1 mt-1 mb-1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>

        <div>
          <v-skeleton-loader
            tile
            height="200"
            type="image"
          ></v-skeleton-loader>
          <v-skeleton-loader
            tile
            height="132"
            type="image"
            class="mb-3"
          ></v-skeleton-loader>
        </div>

        <div>
          <v-card-actions>
            <v-skeleton-loader
              width="800"
              type="heading"
              class="mt-1 mx-n2"
            ></v-skeleton-loader>

            <v-spacer></v-spacer>

            <v-skeleton-loader
              type="image"
              width="170"
              height="20"
              class="rounded-xl mx-n1"
            ></v-skeleton-loader>
          </v-card-actions>
        </div>
      </v-sheet>
    </v-col> -->
    <!-- loading skeleton End -->

    <v-col v-show="this.valid == false">
      <v-alert text type="error">
        Kamu tidak punya akses Ke halaman "Jabatan"
      </v-alert>
    </v-col>

    <v-col v-show="this.valid == true">

      <!-- Alert Data Tersimpan -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      v-model="Alert"
      class="rounded-lg"
      icon="mdi-check-bold"
      transition="slide-x-transition"
    >
      <v-icon class="mr-1">check-circle</v-icon>
      Data Berhasil Disimpan
    </v-alert>

    <!-- Alert Data Berhasil Terubah -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      elevation="4"
      color="success"
      class="rounded-lg"
      icon="mdi-check-bold"
      v-model="AlertBerhasilTerubah"
      transition="slide-x-transition"
    >
      Data Berhasil Diubah
    </v-alert>

    <!-- Alert Data Sudah Terhapus -->
    <v-alert style="position:fixed;z-index:2;right:20px;"
      dark
      dismissible
      color="error"
      elevation="4"
      class="rounded-lg"
      icon="mdi-delete"
      v-model="AlertDataTerhapus"
      transition="slide-x-transition"
    >
      Data Berhasil Dihapus
    </v-alert>

      <!-- v-show="loading == false" -->
      <v-sheet outlined class="elevation-3 pa-2">
        <v-toolbar
          flat
          outlined
          height="55"
          color="dark"
          v-resize="onResize"
        >
          <v-toolbar-title>
            Data Jabatan
          </v-toolbar-title>
          <v-divider inset vertical class="ml-2"></v-divider>
          <v-spacer></v-spacer>

          <div class="text-center mx-2">
            <v-menu
              v-model="MenuExport"
              :close-on-content-click="false"
              :nudge-width="1"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <!-- Btn Export Mode Biasa -->
                <v-btn
                  @click="MenuExport = !MenuExport"
                  class="text-capitalize"
                  v-show="mobile == false"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  text
                  :disabled="loading == true"
                  
                >
                  <v-icon class="ml-n2 mr-1">mdi-table-arrow-right</v-icon>
                  <span class="subtitle-1">Export Data</span>
                  <v-icon class="ml-1 mr-n3">{{ MenuExport ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                </v-btn>
              </template>

              <v-card outlined height="100" class="pa-2" v-show="MenuExport">
                <!-- PDF -->
                <div class="mb-2">
                  <v-btn
                    text
                    block
                    outlined
                    @click.native="PdfExport"
                    class="text-capitalize subtitle-1"
                  >
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>
                </div>

                <!-- EXCEL -->
                <div>
                  <v-btn
                    text
                    block
                    outlined                       
                    @click.native="excelExport"
                    class="text-capitalize subtitle-1"
                  >
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>
              </v-card>
            </v-menu>

            <!-- Btn Export Dialog Mode Mobile -->
            <v-dialog
              v-model="DialogPilihExport"
              max-width="200"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="mobile == true"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  small
                  text
                  fab
                  :disabled="loading == true"
                >
                  <v-icon>mdi-table-arrow-right</v-icon>
                </v-btn>
              </template>
              <v-card class="rounded-lg">
                <v-toolbar dense>
                  <v-layout justify-center>
                    <v-toolbar-title>
                      Pilih Export File
                    </v-toolbar-title>
                  </v-layout>
                </v-toolbar>
                <v-card class="pa-2">
                  <v-btn text block outlined class="text-capitalize subtitle-1 mb-2" @click.native="PdfExport">
                    Pdf
                    <v-icon right>mdi-adobe-acrobat</v-icon>
                  </v-btn>

                  <v-btn text block outlined class="text-capitalize subtitle-1" @click.native="excelExport">
                    Excel
                    <v-icon right>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </v-card>
              </v-card>
            </v-dialog>
          </div>

          <!-- Dialog Tambah Jabatan -->
          <v-dialog
            v-model="DialogTambahJabatan"
            max-width="400px"
            scrollable
            persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <!-- Btn Tambah Mode Desktop -->
              <v-btn
                class="text-capitalize mr-n2"
                v-show="mobile == false && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                text
                :disabled="loading == true"
              >
                <v-icon class="ml-n2 mr-1">mdi-plus-thick</v-icon>
                <span class="mr-n1 subtitle-1">Tambah</span>
              </v-btn>

              <!-- Btn Tambah Mode Mobile -->
              <v-btn 
                class="mr-n2"
                v-show="mobile == true && ValidCreate == true"
                v-bind="attrs"
                v-on="on"
                outlined
                small
                text
                fab
                :disabled="loading == true"
              >
                <v-icon>mdi-plus-thick</v-icon>
              </v-btn>
            </template>
            <v-card outlined class="rounded-lg">
              <v-toolbar flat height="65">
                <v-toolbar-title class="title">
                  <span class="text-h5">{{ formTitleJabatan }}</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn fab text small class="mx-n1" @click="KeluarDialogJabatan">
                  <v-icon>mdi-window-close</v-icon>
                </v-btn>
              </v-toolbar>
                
              <v-divider></v-divider>

              <v-card-text>
                <div class="mr-n3 ml-n3 mb-n2 mt-3">
                  <v-sheet outlined class="rounded-lg pa-5">
                    <v-row> 
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          dense
                          clearable
                          label="No Kode Jabatan"
                          color="dark"
                          v-model="editedItem.NoKodeJabatan"   
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          dense
                          clearable
                          label="Nama Jabatan"
                          color="dark"
                          v-model="editedItem.NamaJabatan"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" lg="12">
                        <!-- <v-text-field
                          dense
                          clearable
                          label="Kode Perusahaan"
                          color="dark"
                          v-model="editedItem.KodePerusahaan"   
                        ></v-text-field> -->
                        <v-autocomplete
                          dense
                          class="rounded-lg"
                          v-model="editedItem.KodePerusahaan"
                          :items="DataPerusahaan"
                          item-text="NamaPerusahaan"
                          item-value="KodePerusahaan"
                          label="Perusahaan"
                        >
                        </v-autocomplete>
                      </v-col>
                      

                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          dense
                          clearable
                          label="Departemen"
                          color="dark"
                          v-model="editedItem.Departemen"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-text-field
                          dense
                          clearable
                          label="Divisi"
                          color="dark"
                          v-model="editedItem.Divisi"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12" md="12" lg="12">
                        <!-- <v-text-field
                          dense
                          clearable
                          label="Atasan"
                          color="dark"
                          v-model="editedItem.Atasan"
                        ></v-text-field> -->
                        <v-autocomplete
                          dense
                          class="rounded-lg"
                          v-model="editedItem.Atasan"
                          :items="DataJabatan"
                          item-text="NamaJabatan"
                          item-value="id"
                          label="Atasan"
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </div>              
              </v-card-text>

              <v-divider></v-divider>
              
              <v-card-actions>
                <span class="d-inline-block text-truncate">
                  <label>Status : <label>{{Status}}</label></label>
                </span>
                <v-spacer></v-spacer>
                <v-btn
                  dark
                  depressed
                  color="red darken-4 mx-n2"
                  class="text-capitalize"
                  @click="Simpan"
                >
                  <v-icon left>mdi-content-save</v-icon>
                  <span class="mr-1">Simpan</span>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <div v-show="loading == true">
            <v-progress-linear
              :active="loading"
              :indeterminate="loading"
              absolute
              bottom
              height="6"
              color="red darken-4"
            ></v-progress-linear>
          </div>
        </v-toolbar>

        <div>
          <ejs-grid
            ref='gridjabatan'
            style="height: 100%;"
            height="43vh"
            gridLines='Both'
            id="jabatanview"
            :dataSource="DataJabatan"
            :allowPaging="true"
            :allowSorting='true'
            :allowResizing='true'
            :allowGrouping='true'
            :toolbar='toolbarOptions'
            :commandClick="commandClick"
            :pageSettings='pageSettings'
            :allowExcelExport='true'
            :allowPdfExport='true'
          >
            <e-columns>
              <e-column headerText="Aksi" textAlign='Center' width="120" :commands="commands" :lockColumn='true' :customAttributes="customAttributes">
                <div class="btn-group">
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowEdit($event)">
                    <i class="fa fa-pencil"></i>
                  </v-btn>
                  <v-btn type="button" class="btn btn-default" (click)="prediemRowDelete($event)">
                    <i class="fa fa-trash"></i>
                  </v-btn>
                </div>
              </e-column>
              <e-column field='NoKodeJabatan' headerText='No Kode Jabatan' textAlign='Left' width=150></e-column>
              <e-column field='KodeJabatan' headerText='Kode Jabatan' textAlign='Left' width=150></e-column>
              <e-column field='NamaJabatan' headerText='Nama Jabatan' width=300></e-column>
              <e-column field='KodePerusahaan.NamaPerusahaan' headerText='Perusahaan' width=150></e-column>
              <e-column field='Departemen' headerText='Departemen' textAlign='Left' width=150></e-column>
              <e-column field='Divisi' headerText='Divisi' textAlign='Left' width=150></e-column>
              <e-column field='Atasan.NamaJabatan' headerText='Atasan' textAlign='Left' width=150></e-column>
              <e-column field='DibuatOleh' headerText='DibuatOleh' textAlign='Left' width=150></e-column>
              <e-column field='DibuatTgl' headerText='DibuatTgl' textAlign='Left' width=150></e-column>
              <e-column field='DiubahOleh' headerText='DiubahOleh' textAlign='Left' width=150></e-column>
              <e-column field='DiubahTgl' headerText='DiubahTgl' textAlign='Left' width=150></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </v-sheet>
    </v-col>
  </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Page, Sort, Group, Resize, Toolbar, Search, CommandColumn, ExcelExport, PdfExport } from "@syncfusion/ej2-vue-grids";
import api from "@/services/http";
Vue.use(GridPlugin);

export default {
  data() {
    return {
      mobile:null,
      valid:null,
      Permission:[],
      ValidCreate:false,
      passbaru:null,
      KodeJabatan:null,
      textAktif:true,
      umenu:[],
      verifpassbaru:null,
      loading:true,
      Kode:null,
      DialogPilihExport:false,
      windowSize: {x: 0, y: 0},
      MenuExport: false,
      commands: [],
      customAttributes : {class: 'customcss'},
      Alert:false,
      akun:[],
      usermenu:null,
      AlertDataTerhapus:false,
      AlertBerhasilTerubah:false,
      user:[],
      token:null,
      DialogTambahJabatan: false,
      form: new FormData,
      editedIndex: -1,

      defaultItem: {
        id:"",
        NoKodeJabatan:"",
        KodeJabatan: "",
        NamaJabatan: "",
        KodePerusahaan: "",
        Departemen: "",
        Divisi: "",
        Atasan: "",
      },
      editedItem: {
        id:"",
        NoKodeJabatan:"",
        KodeJabatan: "",
        NamaJabatan: "",
        KodePerusahaan: "",
        Departemen: "",
        Divisi: "",
        Atasan: "",
      },
      toolbarOptions: ['Search'],
      DataJabatan: [],
      DataPerusahaan:[],
      pageSettings: {pageSize: 10, pageSizes :['5','10','15','20','50','All']},
    };
  },
  

  provide: {
    grid: [Page, Sort, Group, Resize, Toolbar, CommandColumn, Search,ExcelExport,PdfExport ]
  },

 async mounted(){
    this.token = localStorage.getItem('token')
    this.user = JSON.parse(localStorage.getItem('user'))
    // await Promise.all([this.saya()])
    
    // this.editedItem.Tgl_Masuk = this.date
    // this.editedItem.Tgl_Lahir = this.date
    // this.editedItem.Tgl_Phk = this.date
    if (this.Permission.some(a => a == 'R')) {
      this.valid = true
      this.getdata()
    }else{
      this.loading = false
      this.valid = false
    }
    if (this.Permission.some(a => a == 'C')) {
     this.ValidCreate = true
    }
    if (this.Permission.some(a => a == 'U')) {
     this.commands.push({buttonOption: { cssClass: "e-flat Edit", iconCss: "e-edit e-icons", content:' '} })
    }
    if (this.Permission.some(a => a == 'D')) {
     this.commands.push({ buttonOption: { cssClass:"e-flat Delete", iconCss: "e-delete e-icons", content:' '} })
    }
  },

  created(){
    //get action for this page
    console.log('param',this.$route.params.Permission)
    let permisi = this.$route.params.Permission
    this.Permission = permisi == null ? [] : permisi.split(',')
    },

  computed: {
    formTitleJabatan() {
      return this.editedIndex === -1 ? 'Tambah Jabatan Baru' : 'Ubah Data Jabatan'
    },
    Status () {
      return this.editedIndex === -1 ? 'Baru' : 'Ubah'
    },
  },
  
  watch: {
  
      // let a = this.DataJabatan.filter( function(item){return (item.UserMenu == newval.toUpperCase());} )
      // this.umenu = a
    windowSize(){
      if (this.windowSize.x < 450) {
        this.titleClass = "d-none"
        this.mobile = true
      }else{
        this.titleClass = "mr-4"
        this.mobile = false
      }
    },

    DialogTambahJabatan (val) {
      val || this.KeluarDialogJabatan()
    },

    Alert(){
      this.AlertItem()
    },
    AlertDataTerhapus(){
      this.AlertItem()
    },
    AlertBerhasilTerubah(){
      this.AlertItem()
    },
  },

  methods: {
    onResize(){
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
    },
    cariKode(Kode){
      if (Kode != null) {
          this.textAktif = false
        }else{
          this.textAktif = true
        }
      if (Kode == null || Kode == '' || Kode.length == 0) {
        this.editedIndex = -1
          this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      }else{
      this.Kode = Kode.toUpperCase()
      let a = this.DataJabatan.filter( function(item){return (item.Kode == Kode.toUpperCase());} );
      if (a.length > 0) {
          this.editedIndex = 1;
          this.textAktif = false
          this.editedItem.Nama = a[0].Nama
          // this.editedItem.UserMenu = a[0].UserMenu
        }else{
          //do nothing
        }
      }
    },
  async saya(){
       if (this.$menu == undefined) {
         await api.get('/menu?token='+this.token,
        //  { headers: {"Authorization" : `Bearer ${this.token}`} }
         ).then(
        res=>{
          if (res) {
            this.akun = res.data
            let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
            //do noting
          }
        }).catch(err=>{
          // alert(err)
          if (err == "Error: Request failed with status code 401" && this.$route.path != "/login") {
            this.logout()
          }
        })
       }else{
        this.akun = this.$menu
        let route = this.$route.name
            let a = this.akun.filter( function(item){return (item.Route == route);} );
            if (a.length > 0) {
               this.valid = true
            }else{
              this.valid = false
              this.loading = false
            }
       }     
    },
      // cekmenu(){
        
      // },

    Simpan(){
      if(this.formTitleJabatan === "Tambah Jabatan Baru"){
          this.Toast('Data Disimpan')
          api.post('/jabatan?token='+this.token,{
            NoKodeJabatan:this.editedItem.NoKodeJabatan,
            NamaJabatan: this.editedItem.NamaJabatan,
            KodePerusahaan: this.editedItem.KodePerusahaan,
            Departemen: this.editedItem.Departemen,
            Divisi: this.editedItem.Divisi,
            Atasan: this.editedItem.Atasan,
            },{ headers: { 'Content-Type': 'multipart/form-data',"Authorization" : `Bearer ${this.token}` } })
            .then((res)=>{
              if (res) {
                // this.Alert = true
                this.getdata()
              } else {
                //
              }
            })
      }else if(this.formTitleJabatan === "Ubah Data Jabatan"){
        this.Toast('Data Diubah')
         api.put("/jabatan/"+this.editedItem.id+"/?token="+this.token,{
           NoKodeJabatan:this.editedItem.NoKodeJabatan,
            NamaJabatan: this.editedItem.NamaJabatan,
            Departemen: this.editedItem.Departemen,
            KodePerusahaan: typeof this.editedItem.KodePerusahaan == 'object' ? this.editedItem.KodePerusahaan.KodePerusahaan : this.editedItem.KodePerusahaan,
            Divisi: this.editedItem.Divisi,
            Atasan: typeof this.editedItem.Atasan == 'object' ? this.editedItem.Atasan.id : this.editedItem.Atasan,
					},
          { headers: {"Authorization" : `Bearer ${this.token}` } })
					.then((res) => {
						if (!res) {
							//do nothing
						}else{
              this.getdata()
              // this.AlertBerhasilTerubah = true
						}
						
					})
      }
      this.KeluarDialogJabatan()    
    },

    commandClick: function(args) {
      if (args.target.classList.contains("custombutton")){
        // let data = JSON.stringify(args.rowData);
		
        // console.log(args.rowData);
      } else if (args.target.classList.contains("Delete")){
        var r = confirm("Yakin Hapus Data?");
        if (r == true) { 
          this.Toast('Data Dihapus')
          api
            .delete("/jabatan/"+args.rowData.id+'?token='+this.token)
            .then((res) =>{
              // console.log(res);
              if (res) {
                this.getdata()
              // this.AlertDataTerhapus = true
              }
              
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else if (args.target.classList.contains("Edit")){
        this.KodeJabatan = args.rowData.KodeJabatan
        // let data = args
        // this.defaultItem = data.rowData
        this.editedIndex = 1;
        this.editedItem.id = args.rowData.id
        this.editedItem.NoKodeJabatan = args.rowData.NoKodeJabatan
        this.editedItem.KodeJabatan = args.rowData.KodeJabatan
        this.editedItem.NamaJabatan = args.rowData.NamaJabatan
        this.editedItem.Divisi = args.rowData.Divisi
        this.editedItem.KodePerusahaan = args.rowData.KodePerusahaan
        this.editedItem.Departemen = args.rowData.Departemen
        this.editedItem.Atasan = args.rowData.Atasan
        // console.log(data)
        // this.editedItem = data.rowData
        this.DialogTambahJabatan = true
      }
    },

    PdfExport(){
      let data = document.getElementById('jabatanview').ej2_instances[0];
      // console.log(data)
      data.pdfExportProperties = {
        pageOrientation: 'Landscape',
        fileName:"ReportJabatan.pdf"
      }
      data.columns[1].visible = false
      data.columns[4].visible = false
      data.columns[3].visible = false
      // let pdfExportProperties = {
       
      // };
      // this.$refs.gridjabatan.columns[0].visible = false;
      // this.$refs.gridjabatan.columns[1].visible = true;
      this.$refs.gridjabatan.pdfExport(data.pdfExportProperties);
    },
    excelExport(){
      this.$refs.gridjabatan.excelExport();
      // console.log(this.$refs.gridjabatan)
      // let data = document.getElementById('jabatanview').ej2_instances[0];
      // console.log(data)
      // data.excelExport();
    },

    KeluarDialogJabatan () {
      // console.log(this.editedItem)
      this.DialogTambahJabatan = false
      // this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
        this.Kode = null
        this.textAktif = true
      // })
    },

    // beforeExport: function(args) {
      
    // },

    getdata(){
      if (this.valid == true) {
        api.get('/jabatan?token='+this.token,
        // { headers: {"Authorization" : `Bearer ${this.token}`} }
        ).then(
				res => {
					// console.log(res);
          this.getPerusahaan()
          this.DataJabatan = res.data
          this.loading = false
				},
				err => {
					console.log(err);
				}
			)
      }
      
    },
    getPerusahaan(){
        api.get('/perusahaan?token='+this.token).then(
				res => {
          this.DataPerusahaan = res.data
				},
				err => {
					console.log(err);
				}
			)
    },

    Reset() {
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
      })
    },
    logout(){
        api.post('/logout', this.token)
        .then(res=>{
          // console.log(res)
          if (res) {
            //do nothing
          }
        })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        location.reload(false)
      },
    
    Toast(data){
      this.$swal.fire({
      toast: true,
      icon: 'success',
      title: data,
      animation: false,
      position: 'top-right',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true})
    },

    AlertItem(){
      window.setInterval(() => {
        this.Alert = false;
        this.AlertDataTerhapus = false;
        this.AlertBerhasilTerubah = false;
      }, 3000)
    },
  },
}
</script>

<style scoped>
.custom-file-input {
  border: 1px solid #ccc;
  display: inline-block solid #ccc;
  padding: 6px 6px 6px;
  cursor: pointer;
}

.e-grid .e-rowcell.customcss{
  background-color: #d6d6d6;
}
.e-grid .e-headercell.customcss{
  background-color: #ecedee;
}
</style>


{% endraw %}